<template>
    <div>
      <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
      <div v-else>
        <div class="man-title">
          <span>孪生体分类</span>
        </div>
        <div class="table-tree">
          <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading">
            <div class="tree-title"><i class="el-icon-share" style="margin-right:10px;"></i>孪生体分类</div>
            <div v-if="parkData.length>0">
              <div class="tree-park" v-for="(item,index) in parkData" :key="index">
                <div class="park-title" @click.prevent="parkToggle(item)">
                  <div>
                    <span class="icon-triangle" :class="item.disable?'arrow':'unarrow'"></span>
                    <span>{{item.projectName}} ({{item.parkDataList.length}})</span>
                  </div>
                  <div>
                    <el-dropdown placement="bottom-start" trigger="click">
                      <span @click.stop="">
                        <span class="el-dropdown-link">
                          <i class="el-icon-more-outline operate"></i>
                        </span>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item ><div @click="add(item)">创建分类</div></el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <div class="tree-classify" v-if="item.disable">
                  <div v-for="(val,key) in item.parkDataList" :key="key">
                    <div class="classify-title" :class="typeId==val.typeId?'bright':''" @click="selectClassify(item, val, key)">
                      <div>
                        <span class="class-icon iconfont icon-hezi" :style="typeId==val.typeId?'color:#FFF;':'color:#3498db;'"></span>
                        <span>{{val.type}}</span>
                      </div>
                      <div>
                        <el-dropdown placement="bottom-start" trigger="click">
                          <span @click.stop="">
                            <span class="el-dropdown-link">
                              <i class="el-icon-more-outline operate" :style="typeId==val.typeId?'color:#FFF;':'color:#666;'"></i>
                            </span>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item ><div @click="edit(item,val)">编辑分类</div></el-dropdown-item>
                            <el-dropdown-item ><div @click="dlt(item,val)">删除分类</div></el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
          </div>
          <div class="table">
            <el-table :data="tableData" style="width: 100%" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="park" label="分类名称"></el-table-column>
              <el-table-column label="孪生体编号">
                <template slot-scope="scope">
                  {{ scope.row.digitalTwinId }}
                </template>
              </el-table-column>
              <el-table-column label="孪生体名称">
                <template slot-scope="scope">
                  {{ scope.row.digitalTwinName }}
                </template>
              </el-table-column>
              <el-table-column label="绑定设备">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.bindEquipment==0">未绑定</el-tag>
                  <el-tag type="success" v-if="scope.row.bindEquipment==1">已绑定</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间">
                <template slot-scope="scope">
                  {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        操作<i class="el-icon-arrow-down"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="editDevice(scope.row)">编辑</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import page from '@/components/page.vue'
  import dayjs from 'dayjs';
  export default {
    components: {
      page
    },
    data() {
      return {
        loading: false,
        treeLoading: false,
        tableLoading: false,
        size: 50,
        page: 1,
        total: 0,
        parkData: [],//项目数据
  
        tableData: [],//孪生体数数据
        typeId: null,
        park: null,
        classification: null,
        parkName: null,
        dialogFormVisible: false,
        isShow: false,
        staffId: null
      };
    },
    watch:{
      parkName(newv,oldv) {
        if(newv) {
          this.isShow = true
        }else {
          this.isShow = false
        }
      }
    },
    methods: {
      dayjs,
      add(item) {
        this.$router.push({ name: 'iotClassifiTwinsAdd', params: {row: item}})
      },
      edit(item,val) {
        this.$router.push({ name: 'iotClassifiTwinsEdit', params: {row: item, type: val}})
      },
      dlt(item,val) {
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$ajax.post("uinoDigitalTwinTypeDelete", {
            projectId: item.projectId,
            typeId: val.typeId,
          }).then((res) => {
            this.loadProjectList();
          });
        });
      },
      editDevice(row) {
        this.$router.push({ name: 'iotTwinsManageEdit', params: {row: row}})
      },
      // 展开折叠分类
      parkToggle(item) {
        item.disable = !item.disable
      },
      // 选择指定分类
      selectClassify(item, val, key) {
        this.companyId = item.companyId
        this.projectId = item.projectId
        this.typeId = val.typeId
        this.loadListData()
      },
      // 获取项目列表
      loadProjectList() {
        this.treeLoading = true
        this.$ajax.post('iotUinoProjectQuery').then(res => {
          if(res.data.length==0) {
            this.treeLoading = false
          }
          res.data.forEach(v => {
            v.disable = false
            this.loadClassifyList(v)
          });
        }).catch(err=>{
          this.treeLoading = false
        })
      },
      // 获取孪生体分类
      loadClassifyList(projectObj) {
        this.$ajax.post('uinoDigitalTwinTypeQuery', {
          projectId: projectObj.projectId
        }).then(res => {
          let arr = []
          projectObj.parkDataList = res.data
          arr.push(projectObj)
          this.parkData = arr
          this.treeLoading = false
        }).catch(err=>{
          this.treeLoading = false
        })
      },
      // 获取孪生体数据
      loadListData() {
        this.tableLoading = true
        this.$ajax.post('iotTwinsQuery', {
          companyId: this.companyId,
          staffId: this.staffId,
          projectId: this.projectId,
          typeId: this.typeId,
        }).then(res => {
          this.tableData = res.data
          this.tableLoading = false
        }).catch(err=>{
          this.tableLoading = false
        })
      },
      pageChange(val) {
        this.page = val
        sessionStorage.setItem('currentPage', val)
        this.loadListData()
      },
      sizeChange(val) {
        this.size = val
        sessionStorage.setItem('currentSize', val)
        this.loadListData()
      },
      getLastPage() {
        this.page = Number(sessionStorage.getItem('currentPage')) || 1
      },
      getSize() {
        this.size = Number(sessionStorage.getItem('currentSize')) || 50
      },
    },
    created() {
      this.staffId = sessionStorage.getItem('id')
      this.getLastPage()
      this.getSize()
      this.loadProjectList()
    },
    mounted() {
      
    }
  };
  </script>
  
  <style lang="less" scoped>
  .mains {
    padding: 400px 0;
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .table-tree {
    display: flex;
    height: ~'calc(100% - 56px)';
    .tree {
      width: 300px;
      border-right: 4px solid #f6f7fb;
      margin-right: 20px;
    }
    .table {
      flex: 1;
    }
    .operate {
      &:hover {
        color: #409EFF;
      }
    }
    .custom-tree-node {
      font-size: 14px;
    }
  }
  .btn {
    float: right;
  }
  .search {
    padding: 0 10px;
  }
  .tree-park {
    font-size: 14px;
    cursor: pointer;
  }
  .park-title {
    line-height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .4s ease;
    user-select: none;
    .icon-triangle {
      margin-right: 5px;
      transition: all .4s ease;
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 5px solid #ccc;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-radius: 3px;
    }
    .arrow {
      transform: rotate(90deg);
    }
    .unarrow {
      transform: rotate(0deg);
    }
  }
  .park-title:hover {
    background: #f6f7fb;
  }
  .tree-classify {
    line-height: 35px;
  }
  .classify-title {
    line-height: 35px;
    padding-left: 35px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .4s ease;
    user-select: none;
  }
  .class-icon {
    display: inline-block;
    margin-right: 8px;
  }
  .bright {
    background: #409EFF;
    color: #FFF;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
  .cancel-btn {
    margin-left: 20px;
  }
  .import-park {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .park-required {
    color: red;
    margin-right: 3px;
  }
  </style>